<template>
  <div class="market_top">
    <div class="pic">
      <img src="../assets/img/home_tatol_icon.png" alt />
      <p class="title">BTCBAY</p>
    </div>

    <div style="display:flex;align-items:center">
      <div @click="onSearch" class="search">
        <span class="iconfont icon-sousuo"></span>
      </div>
      <div @click="languageChange" class="language">
        <p v-if="$i18n.locale == 'JA'">日本語</p>
        <p v-if="$i18n.locale == 'EN'">English</p>
        <p v-if="$i18n.locale == 'KO'">한국어</p>
        <p v-if="$i18n.locale == 'ZH'">简体中文</p>
      </div>
      <div class="currency">
        <div v-for="item in currencyList" :key="item">
          <p v-if="item == currentCurrency" @click="currencyCommand">{{ item }}</p>
        </div>
      </div>
      <div @click="gonav" class="nav">
        <img src="../assets/img/system-type.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      currencyList: ["USD", "CNY", "KRW", "JPY"],
      currentCurrency: "USD"
    };
  },
  created() {
  },
  mounted() {
    // 判断当前货币
    this.currentCurrency = localStorage.getItem("quoteCurrencyCode");
  },
  methods: {
    //点击搜索
    onSearch() {
      this.$router.push("/search");
    },
    languageChange() {
      this.$router.push("/languageChange");
    },
    // 货币种类点击
    currencyCommand() {
      this.$router.push("/type");
    },
    gonav() {
      this.$router.push("/typeNav");
    }
  }
};
</script>

<style lang="scss" scoped>
.market_top {
  padding: 0 28px 0 32px;
  // width: 100%;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pic {
    display: flex;
    align-items: center;
    // padding-right: 20px;
    img {
      width: 56px;
      height: 56px;
      padding-right: 20px;
    }
  }
  .title {
    font-size: 38px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #333333;
    line-height: 53px;
    padding-right: 30px;
  }
  .search {
    width: 76px;
    height: 48px;
    background: #edf5ff;
    border-radius: 24px;
    margin-right: 20px;
    .iconfont {
      font-size: 24px;
      font-weight: bolder;
      color: #007aff;
      text-align: center;
      line-height: 48px;
      display: flex;
      justify-content: center;
    }
  }
  .flex_row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .language {
    margin-right: 20px;
    padding: 0 15px;
    // width: 138px;
    height: 48px;
    line-height: 48px;
    background: #edf5ff;
    border-radius: 24px;

    p {
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #0079ff;
    }
  }
  .currency {
    margin-right: 28px;
    padding: 0 10px;
    // width: 96px;
    height: 48px;
    line-height: 48px;
    background: #edf5ff;
    border-radius: 24px;
    p {
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #0079ff;
    }
  }
  .nav {
    width: 28px;
    height: 24px;
    img {
      width: 100%;
    }
  }
}
</style>
