<template>
  <div class="footer_wrapper flex_column">
    <div class="foot_top flex_column jcCt">
      <div class="link_wrapper flex_row jcCt">
        <div class="difi_flex" v-if="$i18n.locale == 'ZH'">
          <div v-for="(item, index) in linkList" :key="index">
            <a
              v-if="index == 2"
              :href="item.linkUrl.ZH"
              target="_blank"
              style="border:none"
            >
              <p>
                {{ item.title.ZH }}
              </p>
            </a>
            <a v-else :href="item.linkUrl.ZH" target="_blank">
              <p>
                {{ item.title.ZH }}
              </p>
            </a>
          </div>
        </div>

        <div class="difi_flex flex-wrap" v-if="$i18n.locale == 'EN'">
          <div class="mt5 min-width" v-for="(item, index) in linkList" :key="index">
            <a
              v-if="index == 2"
              :href="item.linkUrl.EN"
              target="_blank"
              style="border:none"
            >
              <p>
                {{ item.title.EN }}
              </p>
            </a>
            <a v-else :href="item.linkUrl.EN" target="_blank">
              <p>
                {{ item.title.EN }}
              </p>
            </a>
          </div>
        </div>
        <div class="difi_flex flex-wrap" v-if="$i18n.locale == 'KO'">
          <div class="mt5 min-width" v-for="(item, index) in linkList" :key="index">
            <a
              v-if="index == 2"
              :href="item.linkUrl.KO"
              target="_blank"
              style="border:none"
            >
              <p>
                {{ item.title.KO }}
              </p>
            </a>
            <a v-else :href="item.linkUrl.KO" target="_blank">
              <p>
                {{ item.title.KO }}
              </p>
            </a>
          </div>
        </div>

        <div class="difi_flex flex-wrap" v-if="$i18n.locale == 'JA'">
          <div class="mt5 min-width" v-for="(item, index) in linkList" :key="index">
            <a
              v-if="index == 2"
              :href="item.linkUrl.JA"
              target="_blank"
              style="border:none"
            >
              <p>
                {{ item.title.JA }}
              </p>
            </a>
            <a v-else :href="item.linkUrl.JA" target="_blank">
              <p>
                {{ item.title.JA }}
              </p>
            </a>
          </div>
        </div>
      </div>
      <div class="codeUrl">
        <img :src="codeUrl" alt="" />
        <p v-if="$i18n.locale == 'ZH'">扫码下载手机app</p>
        <p v-if="$i18n.locale == 'EN'">
          Scan the code to download the mobile app
        </p>
        <p v-if="$i18n.locale == 'JA'">
          コードをスキャンしてモバイルアプリをダウンロードします
        </p>
        <p v-if="$i18n.locale == 'KO'">
          코드를 스캔하여 모바일 앱을 다운로드하십시오
        </p>
      </div>
    </div>
    <div class="img_wrapper flex_row jcCt">
      <a
        :href="item.linkUrl"
        target="_blank"
        v-for="(item, index) in imgArr"
        :key="index"
      >
        <img :src="item.logoUrl" alt="" />
      </a>
    </div>
    <div class="tips flex_column jcCt jcAct">
      <div>© 2020 btcbay. All Rights Reserved</div>
      <!-- <div style="margin-top: 10px">ConWorld Tech (Shenzhen) Co., Ltd.</div> -->
    </div>
  </div>
</template>
<script>
import { getConfigs, getSetting } from "../api/index";
export default {
  data() {
    return {
      imgArr: [],
      linkList: [],
      currentNation: "",
      codeUrl: ""
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getConfigs().then(res => {
        if (res.resultStatus) {
          this.imgArr = res.resultData.socialUrls;
          this.linkList = res.resultData.dataUrls;
        }
      });
      getSetting().then(res => {
        if (res.resultStatus) {
          this.codeUrl = res.resultData.qrUrl;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// @import url('../assets/scss/common.css');
.footer_wrapper {
  width: 100%;
  background: #333;
  padding: 42px 0 21px 0;
  .link_wrapper {
    .difi_flex {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-around;
      div {
        flex: 1;
      }
      a {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-right: 2px solid rgba($color: #fff, $alpha: 0.42);
        p {
          white-space: nowrap;
          text-align: center;
          // opacity: 0.5;
          font-size: 26px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #fff;
          line-height: 37px;
        }
      }
    }
  }
  .codeUrl {
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.42);
    img {
      display: block;
      margin: 0 auto;
      width: 180px;
      height: 180px;
      margin-bottom: 26px;
    }
    p {
      opacity: 0.78;
      font-size: 24px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #fff;
      line-height: 33px;
      margin-bottom: 41px;
    }
  }
  .img_wrapper {
    display: flex;
    justify-content: space-between;
    margin: 30px 302px 20px;
    a {
      img {
        width: 42px;
        height: 42px;
      }
    }
  }
  .tips {
    width: 100%;
    opacity: 0.5;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #fff;
    line-height: 33px;
  }
}
.min-width {
  min-width: 260px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.mt5 {
  margin-top: 10px;
}
</style>
