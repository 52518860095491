<template>
  <div class="market">
    <base-header></base-header>
    <div class="marketValue">
      <div class="header_item flex_row jcCt">
        <div class="item_detail">
          <!-- <p class="money">{{ detail.total.marketCap | formateMoney }}</p> -->
          <div class="item_name">
            <img :src="detail.total.logoUrl" alt v-if="detail.total.logoUrl" />
            <div>
              <p class="item_name_mon">{{ detail.total.currencyName }}</p>
              <p class="money">{{ detail.total.marketCap | formateMoney }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="header_item flex_row jcCt">
        <div class="item_detail">
          <!-- <p class="money">{{ detail.BTC.marketCap | formateMoney }}</p> -->
          <div class="item_name">
            <img class="item--name" :src="detail.BTC.logoUrl" alt v-if="detail.BTC.logoUrl" />
            <div>
              <p class="item_name_mon">{{ detail.BTC.currencyName }}</p>
              <p class="money">{{ detail.BTC.marketCap | formateMoney }}</p>
              <p class="money--mon"
              :class="[
                detail.BTC.changePercentage > 0 ? 'colorGreen' : 'colorRed'
              ]"
              >
                {{ detail.BTC.changePercentage | toDecimal }}%
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="header_item flex_row jcCt">
        <div class="item_detail">
          <!-- <p class="money">{{ detail.ETH.marketCap | formateMoney }}</p> -->
          <div class="item_name item--name">
            <img class="item--name" :src="detail.ETH.logoUrl" alt v-if="detail.ETH.logoUrl" />
            <div>
              <p class="item_name_mon">{{ detail.ETH.currencyName }}</p>
              <p class="money">{{ detail.ETH.marketCap | formateMoney }}</p>
              <p class="money--mon"
                :class="[
                  detail.ETH.changePercentage > 0 ? 'colorGreen' : 'colorRed'
                ]"
              >
                {{ detail.ETH.changePercentage | toDecimal }}%
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="market_nav">
      <div
        class="nav_item"
        :class="[item.index == tableIndex ? 'item_on' : 'item_off',$i18n.locale == 'EN' ? 'en_nav_item' : '',$i18n.locale == 'KO' ? 'ko_nav_item' : '',$i18n.locale == 'JA' ? 'ja_nav_item' : '']"
        v-for="item in navList"
        :key="item.index"
        @click="tabClick(item)"
      >
        <p>{{ item.label }}</p>
        <img
          v-if="item.index == tableIndex"
          class="line"
          src="../../assets/img/home_tab_sel.png"
          alt
        />
      </div>
    </div>
    <!-- 市值榜 -->
    <div v-if="switchList == 'marketValue'" class="market_table">
      <div class="market_table_title">
        <ul>
          <li class="tableTitile" v-for="item in tableTitile" :key="item.index">
            <div @click="globSortTop" class="item" v-if="item.index == 1">
              <p>{{ item.label }}</p>
              <div>
                <div>
                  <!-- PRICE_ASC -->
                  <i
                    :class="
                      sortIndex == 'PRICE_ASC'
                        ? 'iconBlue el-icon-caret-top'
                        : 'icon el-icon-caret-top'
                    "
                  ></i>
                </div>
                <div>
                  <i
                    :class="
                      sortIndex == 'PRICE_DESC'
                        ? 'iconBlue el-icon-caret-bottom'
                        : 'icon el-icon-caret-bottom'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div @click="globSortTopH" class="item" v-if="item.index == 2">
              <p>{{ item.label }}</p>
              <div>
                <div>
                  <!-- PRICE_ASC -->
                  <i
                    :class="
                      sortIndex == 'PERCENT_ASC'
                        ? 'iconBlue el-icon-caret-top'
                        : 'icon el-icon-caret-top'
                    "
                  ></i>
                </div>
                <div>
                  <i
                    :class="
                      sortIndex == 'PERCENT_DESC'
                        ? 'iconBlue el-icon-caret-bottom'
                        : 'icon el-icon-caret-bottom'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div v-if="item.index == 0">
              <p>{{ item.label }}</p>
            </div>
          </li>
        </ul>
      </div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div v-if="noData">暂无数据</div>
        <template>
          <van-list>
            <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
            <div class="table_box">
              <div
                @click="godetail(item)"
                class="table_item"
                v-for="(item, index) in list"
                :key="index"
              >
                <p class="sort selecet">{{ index + 1 }}</p>
                <div class="number">
                  <div class="pic">
                    <img :src="item.logoUrl" alt />
                    <span>{{ item.currencyCode }}</span>
                  </div>
                  <div style="padding-top:10px" class="current_number">
                    <p>{{ item.marketCap | formateMoneyY }}</p>
                    <span>{{ currentCurrency }}</span>
                  </div>
                </div>
                <div class="glob_number">
                  <p
                    :class="[
                      item.changePercentage < 0
                        ? 'glob_number_top'
                        : 'glob_number_top_G'
                    ]"
                  >
                    <!-- {{ item.price | formateMoneyG }} -->
                    <div :class="[
                        item.changePercentage < 0
                          ? 'glob_number_top'
                          : 'glob_number_top_G'
                      ]" v-html="formattedPrice(item.price)"></div>
                  </p>
                  <!-- <p class="glob_number_bottom">
                    {{ item.price | formateMoneyU }}
                  </p> -->
                  <p class="glob_number_bottom"  v-html="detarprice(item.price)"></p>
                </div>
                <div class="TF_number">
                  <p class="colorH" v-if="item.changePercentage == 0">
                    {{ item.changePercentage | toDecomal }}%
                  </p>
                  <p
                    v-else
                    :class="[
                      item.changePercentage < 0 ? 'colorRed' : 'colorGreen'
                    ]"
                  >
                    <span v-if="item.changePercentage > 0"
                      >+{{ item.changePercentage | toDecomal }}%</span
                    >
                    <span v-else>{{ item.changePercentage | toDecomal }}%</span>
                  </p>
                  <!-- <p></p> -->
                </div>
              </div>
            </div>
          </van-list>
        </template>
      </van-pull-refresh>
    </div>
    <!-- 热搜榜 -->
    <div v-if="switchList == 'hotSearch'" class="market_table">
      <div class="market_table_title">
        <ul>
          <li
            class="tableTitile"
            v-for="item in tableTitileH"
            :key="item.index"
          >
            <div @click="globSortTopTH" class="item" v-if="item.index == 1">
              <p>{{ item.label }}</p>
              <div>
                <div>
                  <!-- PRICE_ASC -->
                  <i
                    :class="
                      sortIndexH == 'HEAT_VALUE_ASC'
                        ? 'iconBlue el-icon-caret-top'
                        : 'icon el-icon-caret-top'
                    "
                  ></i>
                </div>
                <div>
                  <i
                    :class="
                      sortIndexH == 'HEAT_VALUE_DESC'
                        ? 'iconBlue el-icon-caret-bottom'
                        : 'icon el-icon-caret-bottom'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div @click="globSortTopHH" class="item" v-if="item.index == 2">
              <p>{{ item.label }}</p>
              <div>
                <div>
                  <!-- PRICE_ASC -->
                  <i
                    :class="
                      sortIndexH == 'PERCENT_ASC'
                        ? 'iconBlue el-icon-caret-top'
                        : 'icon el-icon-caret-top'
                    "
                  ></i>
                </div>
                <div>
                  <i
                    :class="
                      sortIndexH == 'PERCENT_DESC'
                        ? 'iconBlue el-icon-caret-bottom'
                        : 'icon el-icon-caret-bottom'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div v-if="item.index == 0">
              <p>{{ item.label }}</p>
            </div>
          </li>
        </ul>
      </div>
      <van-pull-refresh v-model="isLoadingH" @refresh="onRefreshH">
        <div v-if="noData">暂无数据</div>
        <template>
          <van-list>
            <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
            <div class="table_box">
              <div
                @click="godetail(item)"
                class="table_item"
                v-for="(item, index) in listH"
                :key="index"
              >
                <p class="sort selecet">{{ index + 1 }}</p>
                <div class="number" style="flex:2">
                  <div class="pic">
                    <img :src="item.logoUrl" alt />
                    <span>{{ item.currencyCode }}</span>
                  </div>
                  <div class="current_number">
                    <p style="padding-top:10px">
                      {{ item.marketCap | formateMoney }}
                    </p>
                  </div>
                </div>
                <div class="glob_number" style="flex:4">
                  <hot-img :heatValue="item.heatValue" />
                </div>
                <div class="TF_number">
                  <p v-if="item.changePercentage == 0">
                    {{ item.changePercentage | toDecomal }}%
                  </p>
                  <p style="color:#E8726A" v-if="item.changePercentage < 0">
                    {{ item.changePercentage | toDecomal }}%
                  </p>
                  <p style="color:#51B463" v-if="item.changePercentage > 0">
                    +{{ item.changePercentage | toDecomal }}%
                  </p>
                </div>
              </div>
            </div>
          </van-list>
        </template>
      </van-pull-refresh>
    </div>
    <div v-if="switchList == 'grayFund'" class="market_table gray_table">
      <div class="market_table_title">
        <ul>
          <li
            class="tableTitile"
            v-for="item in tableTitileG"
            :key="item.index"
          >
            <div v-if="item.index == 0">
              <p>{{ item.label }}</p>
            </div>
            <div class="item" v-if="item.index == 1">
              <p>{{ item.label }}</p>
            </div>
            <div class="item" v-if="item.index == 2">
              <p>{{ item.label }}</p>
            </div>
          </li>
        </ul>
      </div>
      <van-pull-refresh v-model="isLoadingG" @refresh="onRefreshG">
        <div v-if="noDataG">暂无数据</div>
        <template>
          <van-list>
            <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
            <div class="table_box">
              <div
                @click="godetail(item)"
                class="table_item"
                v-for="(item, index) in listG"
                :key="index"
              >
                <p class="sort selecet">{{ index + 1 }}</p>
                <div class="number max-number" style="flex:2">
                  <div class="pic">
                    <img :src="item.logoUrl" alt />
                    <span>{{ item.currencyCode }}</span>
                  </div>
                </div>
                <div class="glob_number">
                  <p class="glob_number_top_G gray_fund_center">{{item.contractAmount | formateMoneyNum}}</p>
                  <p class="glob_number_bottom gray_fund_center">{{item.contractValue | formateMoney}}</p>
                </div>
                <div class="glob_number">
                  <p class="glob_number_top_G gray_fund_right">
                    <span v-if="item.changeDayh == 0">
                    {{ item.changeDayh | formateMoneyNum }}
                    </span>
                    <span style="color:#E8726A" v-if="item.changeDayh < 0">
                      {{ item.changeDayh | formateMoneyNum }}
                    </span>
                    <span style="color:#51B463" v-if="item.changeDayh > 0">
                      +{{ item.changeDayh | formateMoneyNum }}
                    </span>
                  </p>
                  <p class="glob_number_bottom gray_fund_right">
                    <span style="color:#51B463"  v-if="item.changeWeek == 0">
                      {{item.changeWeek | formateMoneyNum}}
                    </span>
                    <span style="color:#51B463"  v-if="item.changeWeek > 0">
                      +{{item.changeWeek | formateMoneyNum}}
                    </span>
                     <span style="color:#E8726A" v-if="item.changeWeek < 0">
                      {{item.changeWeek | formateMoneyNum}}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </van-list>
        </template>
      </van-pull-refresh>
    </div>
    <!-- defi -->
    <div v-if="switchList == 'defi'" class="market_table">
      <div class="market_table_title">
        <ul>
          <li class="tableTitile" v-for="item in tableTitile" :key="item.index">
            <div @click="globSortTopD" class="item" v-if="item.index == 1">
              <p>{{ item.label }}</p>
              <div>
                <div>
                  <!-- PRICE_ASC -->
                  <i
                    :class="
                      sortIndexD == 'PRICE_ASC'
                        ? 'iconBlue el-icon-caret-top'
                        : 'icon el-icon-caret-top'
                    "
                  ></i>
                </div>
                <div>
                  <i
                    :class="
                      sortIndexD == 'PRICE_DESC'
                        ? 'iconBlue el-icon-caret-bottom'
                        : 'icon el-icon-caret-bottom'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div @click="globSortTopHD" class="item" v-if="item.index == 2">
              <p>{{ item.label }}</p>
              <div>
                <div>
                  <!-- PRICE_ASC -->
                  <i
                    :class="
                      sortIndexD == 'PERCENT_ASC'
                        ? 'iconBlue el-icon-caret-top'
                        : 'icon el-icon-caret-top'
                    "
                  ></i>
                </div>
                <div>
                  <i
                    :class="
                      sortIndexD == 'PERCENT_DESC'
                        ? 'iconBlue el-icon-caret-bottom'
                        : 'icon el-icon-caret-bottom'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div v-if="item.index == 0">
              <p>{{ item.label }}</p>
            </div>
          </li>
        </ul>
      </div>
      <van-pull-refresh v-model="isLoadingD" @refresh="onRefreshD">
        <div v-if="noDataD">暂无数据</div>
        <template>
          <van-list>
            <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
            <div class="table_box">
              <div
                @click="godetail(item)"
                class="table_item"
                v-for="(item, index) in listD"
                :key="index"
              >
                <p class="sort selecet">{{ index + 1 }}</p>
                <div class="number">
                  <div class="pic">
                    <img :src="item.logoUrl" alt />
                    <span>{{ item.currencyCode }}</span>
                  </div>
                  <div style="padding-top:10px" class="current_number">
                    <p>{{ item.marketCap | formateMoneyY }}</p>
                    <span>{{ currentCurrency }}</span>
                  </div>
                </div>
                <div class="glob_number">
                  <p
                    :class="[
                      item.changePercentage < 0
                        ? 'glob_number_top'
                        : 'glob_number_top_G'
                    ]"
                  >
                    <!-- {{ item.price | formateMoneyG }} -->
                    <div :class="[
                        item.changePercentage < 0
                          ? 'glob_number_top'
                          : 'glob_number_top_G'
                      ]" v-html="formattedPrice(item.price)"></div>
                  </p>
                  <!-- <p class="glob_number_bottom">
                    {{ item.price | formateMoneyU }}
                  </p> -->
                  <p class="glob_number_bottom"  v-html="detarprice(item.price)"></p>

                </div>
                <div class="TF_number">
                  <p class="colorH" v-if="item.changePercentage == 0">
                    {{ item.changePercentage | toDecomal }}%
                  </p>
                  <p
                    v-else
                    :class="[
                      item.changePercentage < 0 ? 'colorRed' : 'colorGreen'
                    ]"
                  >
                    <span v-if="item.changePercentage > 0"
                      >+{{ item.changePercentage | toDecomal }}%</span
                    >
                    <span v-else>{{ item.changePercentage | toDecomal }}%</span>
                  </p>
                  <!-- <p></p> -->
                </div>
              </div>
            </div>
          </van-list>
        </template>
      </van-pull-refresh>
    </div>
    <div v-if="switchList == 'BRC20'" class="market_table">
      <div class="market_table_title">
        <ul>
          <li class="tableTitile" v-for="item in tableTitile" :key="item.index">
            <div @click="globSortTop('BRC20')" class="item" v-if="item.index == 1">
              <p>{{ item.label }}</p>
              <div>
                <div>
                  <!-- PRICE_ASC -->
                  <i
                    :class="
                      sortIndex == 'PRICE_ASC'
                        ? 'iconBlue el-icon-caret-top'
                        : 'icon el-icon-caret-top'
                    "
                  ></i>
                </div>
                <div>
                  <i
                    :class="
                      sortIndex == 'PRICE_DESC'
                        ? 'iconBlue el-icon-caret-bottom'
                        : 'icon el-icon-caret-bottom'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div @click="globSortTop('BRC20')" class="item" v-if="item.index == 2">
              <p>{{ item.label }}</p>
              <div>
                <div>
                  <!-- PRICE_ASC -->
                  <i
                    :class="
                      sortIndex == 'PERCENT_ASC'
                        ? 'iconBlue el-icon-caret-top'
                        : 'icon el-icon-caret-top'
                    "
                  ></i>
                </div>
                <div>
                  <i
                    :class="
                      sortIndex == 'PERCENT_DESC'
                        ? 'iconBlue el-icon-caret-bottom'
                        : 'icon el-icon-caret-bottom'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div v-if="item.index == 0">
              <p>{{ item.label }}</p>
            </div>
          </li>
        </ul>
      </div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div v-if="noData">暂无数据</div>
        <template>
          <van-list>
            <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
            <div class="table_box">
              <div
                @click="godetail(item)"
                class="table_item"
                v-for="(item, index) in listM"
                :key="index"
              >
                <p class="sort selecet">{{ index + 1 }}</p>
                <div class="number">
                  <div class="pic">
                    <img :src="item.logoUrl" alt />
                    <span>{{ item.currencyCode }}</span>
                  </div>
                  <div style="padding-top:10px" class="current_number">
                    <p>{{ item.marketCap | formateMoneyY }}</p>
                    <span>{{ currentCurrency }}</span>
                  </div>
                </div>
                <div class="glob_number">
                  <p
                    :class="[
                      item.changePercentage < 0
                        ? 'glob_number_top'
                        : 'glob_number_top_G'
                    ]"
                  >
                    <!-- {{ item.price | formateMoneyG }} -->
                    <div :class="[
                        item.changePercentage < 0
                          ? 'glob_number_top'
                          : 'glob_number_top_G'
                      ]" v-html="formattedPrice(item.price)"></div>
                  </p>
                  <!-- <p class="glob_number_bottom">
                    {{ item.price | formateMoneyU }}
                  </p> -->
                  <p class="glob_number_bottom"  v-html="detarprice(item.price)"></p>
                </div>
                <div class="TF_number">
                  <p class="colorH" v-if="item.changePercentage == 0">
                    {{ item.changePercentage | toDecomal }}%
                  </p>
                  <p
                    v-else
                    :class="[
                      item.changePercentage < 0 ? 'colorRed' : 'colorGreen'
                    ]"
                  >
                    <span v-if="item.changePercentage > 0"
                      >+{{ item.changePercentage | toDecomal }}%</span
                    >
                    <span v-else>{{ item.changePercentage | toDecomal }}%</span>
                  </p>
                  <!-- <p></p> -->
                </div>
              </div>
            </div>
          </van-list>
        </template>
      </van-pull-refresh>
    </div>
    <base-footer v-if="flag"></base-footer>
  </div>
</template>

<script>
import {
  marketvalue,
  quoteCurrencies,
  conversion,
  marketcurrencies,
  hotSearch,
  grayFund,
  gethalving,
  getMeta
} from '@/api/index';
import hotImg from '../../components/hot-img';
import baseHeader from '../../components/base-header';
import baseFooter from '../../components/base-footer';
export default {
  name: '',
  components: {
    hotImg,
    baseHeader,
    baseFooter
  },
  data() {
    return {
      flag: false,
      tableIndex: 0,
      nationList: [
        {
          label: 'English',
          value: 'EN'
        },
        {
          label: '简体中文',
          value: 'ZH'
        },
        {
          label: '한국어',
          value: 'KO'
        },
        {
          label: '日本語',
          value: 'JA'
        }
      ],
      currentNation: '',
      currencyList: ['USD', 'CNY', 'KRW', 'JPY'],
      currentCurrency: 'USD',
      detail: {
        total: {
          logoUrl: ''
        },
        ETH: {
          logoUrl: ''
        },
        BTC: {
          logoUrl: ''
        }
      },
      list: [],
      isLoading: false, // 下拉的加载图案
      noData: false, // 如果没有数据，显示暂无数据
      sortIndex: '',
      pageNo: 1,
      pageSize: 500,
      sortIndexH: '',
      pageNoH: 1,
      pageSizeH: 500,
      // 灰度基金页数
      pageNoG: 1,
      pageSizeG: 500,
      listH: [],
      listG: [],  //灰度基金列表
      isLoadingH: false, // 下拉的加载图案
      isLoadingG: false, //灰度基金的加载图案
      noDataG: false,  //灰度基金没有数据
      noDataH: false, // 如果没有数据，显示暂无数据
      switchList: 'hotSearch',
      sortIndexD: 'CAP_DESC',
      pageNoD: 1,
      pageSizeD: 500,
      listD: [],
      listM: [],  //meta列表
      isLoadingD: false, // 下拉的加载图案
      noDataD: false, // 如果没有数据，显示暂无数据
      currencySymbol: {
        CNY: '￥',
        USD: '$',
        KRW: '₩',
        JPY: '￥'
      }
    };
    // gethalving
  },
  watch: {
    '$i18n.locale': function() {
      this.getData();
    }
  },
  computed: {
    navList: function() {
      return [
        {
          label: this.$t('热搜榜'),
          index: 0,
          switchList: 'hotSearch'
        },
        {
          label: this.$t('市值榜'),
          index: 1,
          switchList: 'marketValue'
        },
        {
          label: this.$t('灰度基金'),
          index: 2,
          switchList: 'grayFund'
        },
        {
          label: this.$t('Coinlist'),
          index: 3,
          switchList: 'defi'
        },
        {
          label: this.$t('BRC20'),
          index: 4,
          switchList: 'BRC20'
        }
      ];
    },

    tableTitile: function() {
      return [
        {
          label: '',
          index: -1
        },
        {
          label: this.$t('币种/流通市值') + `(${this.currencySymbol[this.currentCurrency]})`,
          index: 0
        },
        {
          label: this.$t('法币价格') + `(${this.currencySymbol[this.currentCurrency]})`,
          index: 1
        },
        {
          label: this.$t('24H涨幅'),
          index: 2
        }
      ];
    },
    tableTitileH: function() {
      return [
        {
          label: '',
          index: -1
        },
        {
          label: this.$t('币种/流通市值') + `(${this.currencySymbol[this.currentCurrency]})`,
          index: 0
        },
        {
          label: this.$t('热度'),
          index: 1
        },
        {
          label: this.$t('今日涨幅'),
          index: 2
        }
      ];
    },
    // 灰度基金表头标题
    tableTitileG: function() {
      return [
        {
          label: '',
          index: -1
        },
        {
          label: this.$t('币种'),
          index: 0
        },
        {
          label: this.$t('持币数量/价值'),
          index: 1
        },
        {
          label: this.$t('1天变化/7天变化'),
          index: 2
        }
      ];
    },
  },

  created() {
    // this.dealCurrentPath();
    // 判断当前语言
    if (localStorage.getItem('currentNation')) {
      for (let n of this.nationList) {
        if (n.value == localStorage.getItem('currentNation')) {
          this.currentNation = n.label;
          this.$i18n.locale = n.value;
        }
      }
    } else {
      this.currentNation = this.nationList[0].label;
      this.$i18n.locale = this.nationList[0].value;
    }
    // 判断当前货币
    if (localStorage.getItem('quoteCurrencyCode')) {
      this.currentCurrency = localStorage.getItem('quoteCurrencyCode');
    } else {
      localStorage.setItem('quoteCurrencyCode', this.currentCurrency);
    }
    this.getData();
  },
  mounted() {
    if (
      navigator.language === 'zh-CN' &&
      !localStorage.getItem('currentNation')
    ) {
      this.currentNation = '简体中文';
      this.$i18n.locale = 'ZH';
      localStorage.setItem('currentNation', 'ZH');
    } else if (
      navigator.language === 'en' &&
      !localStorage.getItem('currentNation')
    ) {
      this.currentNation = 'English';
      this.$i18n.locale = 'EN';
      localStorage.setItem('currentNation', 'EN');
    } else if (
      navigator.language === 'ja' &&
      !localStorage.getItem('currentNation')
    ) {
      this.currentNation = '日本語';
      this.$i18n.locale = 'JA';
      localStorage.setItem('currentNation', 'JA');
    } else if (
      navigator.language === 'ko' &&
      !localStorage.getItem('currentNation')
    ) {
      this.currentNation = '한국어';
      this.$i18n.locale = 'KO';
      localStorage.setItem('currentNation', 'KO');
    }
    this.getmarketcurrencies();
    this.onLoadH()
    this.getgethalving();
    this.getGrayFund()
    this.getMeta()
  },
  filters: {
    toDecomal: function(value) {
      let val = value ? value : 0;
      if (val.toFixed(2) == 0.0) {
        return val;
      } else {
        let percentageVal = val.toFixed(2);
        return parseFloat(percentageVal);
      }
    },
    formateMoney: function(value) {
      let currencyCode = localStorage.getItem('quoteCurrencyCode');
      let priceConversion = JSON.parse(localStorage.getItem('priceConversion'));
      let currencySymbol = {
        CNY: '￥',
        USD: '$',
        KRW: '₩',
        JPY: '￥'
      };
      let lastValue = '';
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value * rate; //转换后的值
      if (
        currencyCode == 'CNY' &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}亿`;
      } else if (
        currencyCode == 'CNY' &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}万`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}B`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}M`;
      }
      let str = `${currencySymbol[currencyCode]}${lastValue}`;
      return str;
    },
    formateMoneyY: function(value) {
      let currencyCode = localStorage.getItem('quoteCurrencyCode');
      let priceConversion = JSON.parse(localStorage.getItem('priceConversion'));
      let currencySymbol = {
        CNY: '￥',
        USD: '$',
        KRW: '₩',
        JPY: '￥'
      };
      let lastValue = '';
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value * rate; //转换后的值
      if (
        currencyCode == 'CNY' &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}亿`;
      } else if (
        currencyCode == 'CNY' &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}万`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}B`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}M`;
      }
      let str = `${lastValue}`;
      return str;
    },
    formateMoneyG: function(value) {
      let currencyCode = localStorage.getItem('quoteCurrencyCode');
      let priceConversion = JSON.parse(localStorage.getItem('priceConversion'));
      let lastValue = '';
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value * rate; //转换后的值
      if (
        currencyCode == 'CNY' &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}亿`;
      } else if (
        currencyCode == 'CNY' &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}万`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}B`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}M`;
      }
      else if (covertValue < 1) {
        let fixedValue = covertValue.toFixed(20); // 确保有足够的小数位数
        const data = fixedValue.split(".");
        if (data[1]) {
          let zeroCount = data[1].search(/[^0]/); // 查找第一个非零字符的位置
          const leadingZeros = zeroCount === -1 ? data[1].length : zeroCount; // 计算前导零的个数
          if (leadingZeros <= 4) {
            let localeValue = covertValue.toString().substring(0, 8);
            lastValue = localeValue;
          } else {
            let nonZeroPart = data[1].slice(leadingZeros).substring(0, 4);
            nonZeroPart = nonZeroPart.replace(/0+$/, "");
            const formattedValue =
              nonZeroPart.length > 0
                ? `0.0<sub>${leadingZeros}</sub>${nonZeroPart}`
                : `0.0<sub>${leadingZeros}</sub>`;
            lastValue = formattedValue;
          }
        }
      }
      let str = `${lastValue}`;
      return str;
    },
    // 数量处理
    formateMoneyNum: function(value) {
      let currencyCode = localStorage.getItem("quoteCurrencyCode");
      let lastValue = "";
      lastValue = value.toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value; //转换后的值
      if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}亿`;
      } else if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}万`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}B`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}M`;
      }
      let str = `${lastValue}`;
      return str;
    },
    formateMoneyU: function(value) {
      let currencyCode = 'USD';
      let priceConversion = JSON.parse(localStorage.getItem('priceConversion'));
      let currencySymbol = {
        CNY: '￥',
        USD: '$',
        KRW: '₩',
        JPY: '￥'
      };
      let lastValue = '';
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value * rate; //转换后的值
      if (
        currencyCode == 'CNY' &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}亿`;
      } else if (
        currencyCode == 'CNY' &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}万`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}B`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}M`;
      }  else if (covertValue < 1) {
        let fixedValue = covertValue.toFixed(20); // 确保有足够的小数位数
        const data = fixedValue.split(".");
        if (data[1]) {
          let zeroCount = data[1].search(/[^0]/); // 查找第一个非零字符的位置
          const leadingZeros = zeroCount === -1 ? data[1].length : zeroCount; // 计算前导零的个数
          if (leadingZeros <= 4) {
            let localeValue = covertValue.toString().substring(0, 8);
            lastValue = localeValue;
          } else {
            let nonZeroPart = data[1].slice(leadingZeros).substring(0, 4);
            nonZeroPart = nonZeroPart.replace(/0+$/, "");
            const formattedValue =
              nonZeroPart.length > 0
                ? `0.0<sub>${leadingZeros}</sub>${nonZeroPart}`
                : `0.0<sub>${leadingZeros}</sub>`;
            lastValue = formattedValue;
          }
        }
      }
      let str = `${currencySymbol[currencyCode]}${lastValue}`;
      return str;
    },
    toDecimal(value) {
      if (value != null) {
        let val = value ? value : 0;
        if (val.toFixed(2) == 0.0) {
          return val;
        } else {
          let percentageVal = val.toFixed(2);
          return parseFloat(percentageVal);
        }
      }
    }
  },
  methods: {
    formattedPrice(val) {
      return this.$options.filters.formateMoneyG(val);
    },
    detarprice(val) {
      return this.$options.filters.formateMoneyU(val);
    },
    //详情页面
    godetail(item) {
      localStorage.setItem('selectCurrency',JSON.stringify(item))
      this.$router.push({
        path: 'coin-detail',
        query: { currencyId: item.currencyId,symbol: item.currencyCode}
      });
    },
    //排序
    globSortTop(type) {
      this.isLoading = true;
      this.pageNo = 1;
      this.list = [];
      if (
        this.sortIndex == '' ||
        this.sortIndex == 'PERCENT_ASC' ||
        this.sortIndex == 'PERCENT_DESC'
      ) {
        this.sortIndex = 'PRICE_ASC';
      } else if (this.sortIndex == 'PRICE_ASC') {
        this.sortIndex = 'PRICE_DESC';
      } else if (this.sortIndex == 'PRICE_DESC') {
        this.sortIndex = 'PRICE_ASC';
      }
      console.log(this.sortIndex,'this.sortIndex');
      console.log(type,'type');
      if(type == 'BRC20') {
         this.onLoadM();
      } else {
         this.onLoad();
      }
    },
    globSortTopH() {
      this.pageNo = 1;
      this.isLoading = true;
      this.list = [];
      if (
        this.sortIndex == '' ||
        this.sortIndex == 'PRICE_ASC' ||
        this.sortIndex == 'PRICE_DESC'
      ) {
        this.sortIndex = 'PERCENT_ASC';
      } else if (this.sortIndex == 'PERCENT_ASC') {
        this.sortIndex = 'PERCENT_DESC';
      } else if (this.sortIndex == 'PERCENT_DESC') {
        this.sortIndex = 'PERCENT_ASC';
      }
      this.onLoad();
    },
    globSortTopTH() {
      this.isLoadingH = true;
      this.pageNoH = 1;
      this.listH = [];
      if (
        this.sortIndexH == '' ||
        this.sortIndexH == 'PERCENT_ASC' ||
        this.sortIndexH == 'PERCENT_DESC'
      ) {
        this.sortIndexH = 'HEAT_VALUE_ASC';
      } else if (this.sortIndexH == 'HEAT_VALUE_ASC') {
        this.sortIndexH = 'HEAT_VALUE_DESC';
      } else if (this.sortIndexH == 'HEAT_VALUE_DESC') {
        this.sortIndexH = 'HEAT_VALUE_ASC';
      }
      // this.gethotSearch();
      this.onLoadH();
    },
    globSortTopHH() {
      this.pageNoH = 1;
      this.isLoadingH = true;
      this.listH = [];
      if (
        this.sortIndexH == '' ||
        this.sortIndexH == 'HEAT_VALUE_ASC' ||
        this.sortIndexH == 'HEAT_VALUE_DESC'
      ) {
        this.sortIndexH = 'PERCENT_ASC';
      } else if (this.sortIndexH == 'PERCENT_ASC') {
        this.sortIndexH = 'PERCENT_DESC';
      } else if (this.sortIndexH == 'PERCENT_DESC') {
        this.sortIndexH = 'PERCENT_ASC';
      }
      // this.gethotSearch();
      this.onLoadH();
    },
    globSortTopD() {
      this.isLoadingD = true;
      this.pageNoD = 1;
      this.listD = [];
      if (
        this.sortIndexD == '' ||
        this.sortIndexD == 'PERCENT_ASC' ||
        this.sortIndexD == 'PERCENT_DESC'
      ) {
        this.sortIndexD = 'PRICE_ASC';
      } else if (this.sortIndexD == 'PRICE_ASC') {
        this.sortIndexD = 'PRICE_DESC';
      } else if (this.sortIndexD == 'PRICE_DESC') {
        this.sortIndexD = 'PRICE_ASC';
      }
      this.onLoadD();
    },
    globSortTopHD() {
      this.pageNoD = 1;
      this.isLoadingD = true;
      this.listD = [];
      if (
        this.sortIndexD == '' ||
        this.sortIndexD == 'PRICE_ASC' ||
        this.sortIndexD == 'PRICE_DESC'
      ) {
        this.sortIndexD = 'PERCENT_ASC';
      } else if (this.sortIndexD == 'PERCENT_ASC') {
        this.sortIndexD = 'PERCENT_DESC';
      } else if (this.sortIndexD == 'PERCENT_DESC') {
        this.sortIndexD = 'PERCENT_ASC';
      }
      this.onLoadD();
    },
    //市值
    getmarketcurrencies() {
      marketcurrencies({
        language: this.$i18n.locale,
        sortIndex: this.sortIndex,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      })
        .then(res => {
          if (res.resultStatus) {
              this.flag = true;
            this.isLoading = false;
            for (let i = 0; i < res.resultData.dataList.length; i++) {
              this.list.push(res.resultData.dataList[i]);
            }

            if (this.list.length == 0 && this.pageNo === 1) {
              this.noData = true;
            }
          }
        })
        .catch(() => {});
    },
    //热搜
    gethotSearch() {
      hotSearch({
        language: this.$i18n.locale,
        sortIndex: this.sortIndexH,
        pageNo: this.pageNoH,
        pageSize: this.pageSizeH
      })
        .then(res => {
          if (res.resultStatus) {
            this.flag = true;
            this.isLoadingH = false;
            for (let i = 0; i < res.resultData.length; i++) {
              this.listH.push(res.resultData[i]);
            }

            if (this.list.length == 0 && this.pageNo === 1) {
              this.noDataH = true;
            }
          }
        })
        .catch(() => {});
    },
    // 灰度基金
    getGrayFund() {
      grayFund({
        language: this.$i18n.locale,
        pageNo: this.pageNoG,
        pageSize: this.pageSizeG
      })
        .then(res => {
          if (res.resultStatus) {
            this.flag = true;
            this.isLoadingG = false;
            for (let i = 0; i < res.resultData.length; i++) {
              this.listG.push(res.resultData[i]);
            }

            if (this.listG.length == 0 && this.pageNoG === 1) {
              this.noDataG = true;
            }
          }
        })
        .catch(() => {});
    },
    //defi
    getgethalving() {
      gethalving({
        language: this.$i18n.locale,
        sortIndex: this.sortIndexD,
        pageNo: this.pageNoD,
        pageSize: this.pageSizeD
      })
        .then(res => {
          if (res.resultStatus) {
            this.isLoadingD = false;
            this.flag = true;
            // this.flag = true;
            for (let i = 0; i < res.resultData.dataList.length; i++) {
              this.listD.push(res.resultData.dataList[i]);
            }

            if (this.listD.length == 0 && this.pageNo === 1) {
              this.noDataD = true;
            }
          }
        })
        .catch(() => {});
    },
    // 获取meta列表
    getMeta() {
      getMeta({
        language: this.$i18n.locale,
        sortIndex: this.sortIndex,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      })
        .then(res => {
          if (res.resultStatus) {
            this.flag = true;
            this.isLoading = false;
            this.listM = res.resultData
            console.log(this.listM,'listM列表');
            if (this.listM.length == 0 && this.pageNo === 1) {
              this.noData = true;
            }
          }
        })
        .catch(() => {});
    },
    onLoad() {
      this.getmarketcurrencies();
    },
    onLoadM() {
      this.getMeta();
    },
    onRefresh() {
      // 重新初始化这些属性
      this.isLoading = false;
      this.list = [];
      this.pageNo = 1;
      this.pageSize = 500;
      this.noData = false;
      this.flag = false;
      // 请求信息
      this.getmarketcurrencies();
    },
    onLoadH() {
      this.gethotSearch();
    },
    onRefreshH() {
      // 重新初始化这些属性
      this.isLoadingH = false;
      this.listH = [];
      this.pageNoH = 1;
      this.pageSizeH = 500;
      this.noDataH = false;
      this.flag = false;
      // 请求信息
      this.gethotSearch();
    },
    // 灰度基金下拉刷新
    onRefreshG() {
      this.isLoadingG = false;
      this.listG = [];
      this.pageNoG = 1;
      this.pageSizeG = 500;
      this.noDataG = false;
      this.flag = false;
      // 请求信息
      this.getGrayFund();
    },
    onLoadD() {
      this.getgethalving();
    },
    onRefreshD() {
      // 重新初始化这些属性
      this.isLoadingD = false;
      this.listD = [];
      this.pageNoD = 1;
      this.pageSizeD = 500;
      this.noDataD = false;
      this.flag = false;
      // 请求信息
      this.getgethalving();
    },
    getPriceConversion() {
      setInterval(() => {
        conversion().then(res => {
          localStorage.setItem(
            'priceConversion',
            JSON.stringify(res.resultData)
          );
        });
      }, 1800000);
    },
    languageChange() {
      this.$router.push('/languageChange');
    },
    // 货币种类点击
    currencyCommand() {
      this.$router.push('/type');
    },
    gonav() {
      this.$router.push('/typeNav');
    },
    getData() {
      let param = {
        language: this.$i18n.locale
      };
      marketvalue(param).then(res => {
        if (res.resultStatus) {
          this.detail = res.resultData;
        }
      });
    },
    //市值热搜切换
    tabClick(item) {
      this.sortIndex = item.index == 0 ? 'CAP_DESC' : '';
      this.tableIndex = item.index;
      this.switchList = item.switchList;
      // this.getData();
    },
    formatter(row, column) {
      return row.address;
    },
    //点击搜索
    onSearch() {
      this.$router.push('/search');
    }
  }
};
</script>

<style lang="scss" scoped>
.market {
  .market_top {
    padding: 0 28px 0 32px;
    // width: 100%;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pic {
      width: 56px;
      height: 56px;
      // padding-right: 20px;
      img {
        width: 100%;
      }
    }
    .title {
      font-size: 38px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
      line-height: 53px;
      padding-right: 30px;
    }
    .search {
      width: 76px;
      height: 48px;
      background: #edf5ff;
      border-radius: 24px;
      // margin-right: 20px;
      .iconfont {
        font-size: 24px;
        font-weight: bolder;
        color: #007aff;
        text-align: center;
        line-height: 48px;
        display: flex;
        justify-content: center;
      }
    }
    .flex_row {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .language {
      // margin-right: 20px;
      padding: 0 15px;
      // width: 138px;
      height: 48px;
      line-height: 48px;
      background: #edf5ff;
      border-radius: 24px;

      p {
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #0079ff;
      }
    }
    .currency {
      // margin-right: 26px;
      padding: 0 10px;
      // width: 96px;
      height: 48px;
      line-height: 48px;
      background: #edf5ff;
      border-radius: 24px;
      p {
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #0079ff;
      }
    }
    .nav {
      width: 28px;
      height: 24px;
      img {
        width: 100%;
      }
    }
  }
  .marketValue {
    // padding-top: 32px;
    border-bottom: 20px solid #eff3f5;
    // height: 180px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 32px 47px 25px;
    .item_detail {
      .money {
        font-size: 22px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #333333;
        line-height: 30px;
        margin-top: 10px;
      }
      .item_name {
        // text-align: center;
        justify-content: center;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-right: 15px;
        }
        .item_name_mon {
          font-size: 22px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
          line-height: 30px;
        }
        .money--mon {
          text-align: left;
          margin-top: 10px;
        }
      }
      .item--name {
        margin-bottom: 30px;
      }
    }
  }
  .market_nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .nav_item {
      padding-top: 24px;
      flex: 1;
    }
    .en_nav_item {
      flex: 2;
    }
    .en_nav_item:last-child {
      flex: 1;
    }
    .ko_nav_item {
      flex: 2;
    }
    .ko_nav_item:nth-child(3) {
      flex: 3;
    }
    .ko_nav_item:last-child {
      flex: 1;
      padding-top: 30px;
    }
    .ja_nav_item:last-child {
      padding-top: 28px;
    }
    .item_off {
      // height: 88px;
      // line-height: 88px;
      font-size: 28px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #333333;
    }
    .item_on {
      // height: 88px;
      // line-height: 88px;
      font-size: 28px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #007aff;
    }
    .line {
      // margin: 0 auto;
      height: 5px;
      // img {
      //   width: 40px;
      //   height: 5px;
      // }
    }
  }
  .gray_table {
    .market_table_title {
      ul {
        .tableTitile {
          &:nth-child(2) {
            flex: 3 !important;
          }
          &:nth-child(3) {
            flex: 3 !important;
          }
          &:nth-child(4) {
            flex: 3 !important;
          }
        }
      }
    }
  }
  .market_table {
    // width: 375px;
    .market_table_title {
      ul {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        line-height: 33px;
        background: #eff3f5;

        .tableTitile {
          padding: 10px 0;
          margin: 0 auto;
          font-size: 24px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #a4afb5;
          display: flex;
          align-items: center;
          justify-content: center;
          .item {
            display: flex;
            align-items: center;
          }

          &:nth-child(2) {
            flex: 3;
          }
          &:nth-child(3) {
            flex: 3;
          }
          &:nth-child(4) {
            flex: 2;
          }
          p {
            text-align: center;
            display: inline-block;
            margin-right: 2px;
          }

          .icon {
            margin: -12px 0;
            display: block;
          }
          .iconBlue {
            margin: -12px 0;
            display: block;
            color: #007aff;
          }
        }
      }
    }
    .table_box {
      .table_item {
        padding: 0 32px;
        align-items: center;
        justify-content: space-between;
        display: flex;
        height: 160px;
        &:first-child {
          .selecet {
            color: #ff7600;
          }
        }
        &:nth-child(2) {
          .selecet {
            color: #f1ab19;
          }
        }
        &:nth-child(3) {
          .selecet {
            color: #96bfec;
          }
        }
        .sort {
          font-size: 36px;
          font-family: DINAlternate, DINAlternate-Bold;
          font-weight: 700;
          text-align: left;
          color: #a4afb5;
          line-height: 42px;
          margin-right: 32px;
        }
        .max-number {
          width: 135px;
        }
        .number {
          flex: 3;
          // margin-right: 20px;
          .pic {
            display: flex;
            align-items: center;
            img {
              margin-right: 16px;
              width: 40px;
              height: 40px;
            }
            span {
              font-size: 28px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #333333;
              line-height: 40px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .current_number {
            // margin-right: 30px;
            display: flex;
            align-items: center;
            font-size: 22px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #999999;
            line-height: 30px;
            p {
              margin-right: 8px;
            }
          }
        }
        .glob_number {
          flex: 4;
          .glob_number_top {
            width: 200px;
            font-size: 36px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: right;
            color: #e35e55;
            line-height: 50px;
          }
          .glob_number_top_G {
            width: 200px;
            font-size: 36px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: right;
            color: #51b463;
            line-height: 50px;
          }
          .glob_number_bottom {
            width: 200px;
            font-size: 22px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: right;
            color: #999999;
            line-height: 30px;
          }
          .gray_fund_center,
          .gray_fund_right {
            width: 250px;
          }
        }
        .TF_number {
          flex: 1;
          .colorRed {
            width: 132px;
            height: 60px;
            background: #e8726a;
            border-radius: 5px;
            font-size: 28px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 60px;
          }
          .colorGreen {
            background: #51b463;
            width: 132px;
            height: 60px;
            border-radius: 5px;
            font-size: 28px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 60px;
          }
          .colorH {
            background: #ccc;
            width: 132px;
            height: 60px;
            border-radius: 5px;
            font-size: 28px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 60px;
          }
        }
      }
    }
  }
}
</style>
