<template>
  <div class="hot_img">
    <div v-for="(item, index) in imgArr" class="marginRight5" :key="index">
      <img v-if="item == 2" src="../assets/img/fire1.png" alt="" />
      <img v-else src="../assets/img/fire2.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: ['heatValue'],
  data() {
    return {
      imgArr: []
    };
  },
  created() {
    let heatValue = this.heatValue;
    if (heatValue % 2 == 0) {
      let num = heatValue / 2;
      let tempArr = [];
      for (let i = 0; i < num; i++) {
        tempArr.push(2);
      }
      this.imgArr = tempArr;
    } else {
      let num = Number.parseInt(heatValue / 2);
      let tempArr = [];
      for (let i = 0; i < num; i++) {
        tempArr.push(2);
      }
      tempArr.push(1);
      this.imgArr = tempArr;
    }
  }
};
</script>
<style lang="scss" scoped>
.hot_img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .marginRight5 {
    margin-right: 5px;
  }
  img {
    width: 40px;
    height: 40px;
  }
}
</style>
